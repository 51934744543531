var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "px-6" },
    [
      _c(
        "div",
        { staticClass: "text-center", attrs: { width: "100%" } },
        [
          _c("v-icon", { attrs: { "x-large": "" } }, [
            _vm._v(" " + _vm._s(_vm.icon))
          ]),
          _c("div", { staticClass: "my-2 caption" }, [
            _vm._v(" " + _vm._s(_vm.subTitle) + " ")
          ])
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-2" }),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }